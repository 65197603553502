<script setup lang="ts">
import { PawBadge, PawButtonOutlined, PawButtonFilled, PawHeading } from '@lyka-pet-food/lyka-common'
import { dateToLocalString } from '@/utils/dateToLocalString'

const props = withDefaults(
  defineProps<{
    oldDeliveryDate: string
    newDeliveryDate: string
    deliverySlot: string
    delayDelivery?: boolean
  }>(),
  {
    delayDelivery: true,
  },
)

const emits = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm'): void
}>()
</script>

<template>
  <div class="tw-space-y-8">
    <img
      src="@/assets/icons/x-mark.svg"
      class="tw-absolute tw-right-6 tw-top-6 tw-cursor-pointer tw-hidden md:tw-block"
      @click="emits('cancel')"
    />

    <PawHeading size="3xl">Confirm delivery change</PawHeading>

    <div class="tw-flex tw-justify-center tw-space-x-4 tw-items-end">
      <div class="tw-flex tw-flex-col tw-justify-center tw-space-y-1">
        <div>
          <PawBadge text="Old" />
        </div>
        <div data-testid="test-old-delivery-date">{{ dateToLocalString(props.oldDeliveryDate) }}</div>
      </div>
      <img src="@/assets/icons/arrow-right-regular.svg" class="tw-pb-1" />
      <div class="tw-flex tw-flex-col tw-justify-center tw-space-y-1">
        <div>
          <PawBadge text="New" class="!tw-bg-surface-brand-accent !tw-text-content-invert" />
        </div>
        <div data-testid="test-new-delivery-date">{{ dateToLocalString(props.newDeliveryDate) }}</div>
      </div>
    </div>

    <div v-if="props.delayDelivery" class="tw-text-body-md">
      {{
        new Date(newDeliveryDate) < new Date(oldDeliveryDate)
          ? 'All future deliveries will be brought forward'
          : 'All future deliveries will be pushed back'
      }}
    </div>
    <div v-else class="tw-text-body-md">This change will be applied to all future deliveries.</div>

    <div class="tw-space-y-2">
      <div class="tw-text-body-md">Delivery window:</div>
      <div class="tw-flex tw-justify-center tw-space-x-4 tw-items-center tw-font-bold">
        <div
          data-testid="test-am-slot"
          v-if="props.delayDelivery || props.deliverySlot === 'AM'"
          :class="props.deliverySlot === 'AM' ? 'tw-slot-selected' : 'tw-slot-unselected'"
        >
          12AM - 7AM
        </div>
        <div
          data-testid="test-pm-slot"
          v-if="props.delayDelivery || props.deliverySlot === 'PM'"
          :class="props.deliverySlot === 'PM' ? 'tw-slot-selected' : 'tw-slot-unselected'"
        >
          8AM - 6PM
        </div>
      </div>
    </div>

    <div class="tw-flex tw-justify-between tw-space-x-4">
      <PawButtonOutlined data-testid="test-cancel-button" class="tw-border-solid" block @click="emits('cancel')">
        Cancel</PawButtonOutlined
      >
      <PawButtonFilled data-testid="test-confirm-button" block @click="emits('confirm')">Confirm</PawButtonFilled>
    </div>
  </div>
</template>

<style scoped>
.tw-slot-selected {
  @apply tw-bg-surface-brand-secondary tw-rounded-xl tw-pt-1 tw-pb-0.5 tw-px-3;
}

.tw-slot-unselected {
  @apply tw-border-2 tw-border-b-surface-brand-secondary tw-rounded-xl tw-pt-1 tw-pb-0.5 tw-px-3;
}
</style>
