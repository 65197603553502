<script setup lang="ts">
import { RetrieveDogReturnDto } from '@repo/api-types/src/entities'
import { PawHeading, PawButtonOutlined } from '@lyka-pet-food/lyka-common'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { computed } from 'vue'
import { useProductsStore } from '@/stores/products'
import { ProductType } from '@/types/products'

const props = defineProps<{
  dog: RetrieveDogReturnDto
}>()

const productsStore = useProductsStore()
const subscriptionsStore = useSubscriptionsStore()

const mealLineItemsNames = computed(() => {
  const subscription = subscriptionsStore.getSubscriptionByPetId(props.dog?.identifier)
  const subProducts = subscription?.subscriptionForProducts?.filter(
    (lineItem) => productsStore.getProductById(lineItem.productId)?.productType === ProductType.MEAL,
  )
  return subProducts?.map((i) => productsStore.getProductById(i.productId)?.displayName)
})

const emit = defineEmits<{
  (e: 'editMeals', dog: RetrieveDogReturnDto): void
}>()
</script>

<template>
  <div class="tw-w-full tw-flex tw-justify-between tw-mb-4">
    <PawHeading size="2xl" type="subheading">Meals</PawHeading>
    <div class="">
      <PawButtonOutlined class="tw-h-8 tw-border-solid" @click="emit('editMeals', dog)">Edit</PawButtonOutlined>
    </div>
  </div>

  <div>
    <span>{{ mealLineItemsNames?.join(', ') }}</span>
  </div>
</template>
